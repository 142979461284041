import React, { useEffect, useState } from 'react'
import style from './collectionReportList.module.css'
import machineImage1 from '../../assests/collectiontreport.png'
import machineImage2 from '../../assests/collectiontreport.png'
import machineImage3 from '../../assests/collectiontreport.png'
import ReactPaginate from 'react-paginate';
import { IconContext } from 'react-icons';
import { AiFillLeftCircle, AiFillRightCircle } from 'react-icons/ai';
import { Button, Form, Modal, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const CollectionReportList = () => {

  const navigate = useNavigate()
  const [data, setData] = useState()
  const [page, setPage] = useState(0);
  const [filterData, setFilterData] = useState();
  const [n, setN] = useState(9);


  const perPageOptions = [
    { value: 9, label: '9' },
    { value: 20, label: '20' },
    { value: 30, label: '30' },
  ];

  // useEffect(() => {
  //     setFilterData(
  //         locations?.filter((item, index) => {
  //             return (index >= page * n) & (index < (page + 1) * n);
  //         })
  //     );
  // }, [page, n, locations]);

  const handlePageChange = (selectedPage) => {
    setPage(selectedPage.selected);
  };

  const handlePerPageChange = (event) => {
    setN(Number(event.target.value));
  };

  const [showModal, setShowModal] = useState(false);
  const [selectedAccordionData, setSelectedAccordionData] = useState(null);

  const handleAccordionItemClick = (data) => {
    setSelectedAccordionData(data);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handlePrint = () => {
    const content = document.getElementById('modalContent');

    if (content) {
      const pri = document.getElementById('ifmcontentstoprint').contentWindow;
      pri.document.open();
      pri.document.write(`
        <link rel="stylesheet" type="text/css" href="./collectionReportList.module.css">
        <div class="printStyles">
          ${content.innerHTML}
        </div>
      `);
      pri.document.close();
      pri.focus();
      pri.print();
    }
  };

  const [showImageModal, setShowImageModal] = useState(false);

  const openFullscreen = (index) => {
    setShowImageModal(true);
  };

  const machineImages = [machineImage1];

  return (
    <>
      <iframe
        id="ifmcontentstoprint"
        style={{ height: '0px', width: '0px', position: 'absolute' }}
      ></iframe>
      <div className="accordion" id="accordionExample">
        <div className="accordion-item border-0">
          <h2 className="accordion-header" id="headingOne">
            <button className="accordion-button px-5 py-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
              <div className={`${style.listrow} row rounded-2 w-100 align-items-center`}>
                <div className="col-2 p-0">
                  <div className='d-flex align-items-center'>
                    <p className='fw-bold mb-0 '>12 Dec, 2023</p>
                  </div>
                </div>
                <div className="col-2  p-0">
                  <div>
                    <p className='fw-bold mb-0 '>Milanie Olmstedr</p>
                  </div>
                </div>
                <div className="col-2  p-0">
                  <div>
                    <p className='fw-bold mb-0 '>MoonLight Bar</p>
                  </div>
                </div>
                <div className="col-1  p-0">
                  <div>
                    <p className='fw-bold mb-0 ms-2'>6</p>
                  </div>
                </div>
                <div className="col-2  p-0">
                  <div>
                    <p className='fw-bold mb-0 ms-3'>4</p>
                  </div>
                </div>
                <div className="col-1 p-0">
                  <div>
                    <p className='fw-bold mb-0 ms-3'>2</p>
                  </div>
                </div>
                <div className="col-2  d-flex align-items-center justify-content-between">
                  <div>
                    <p className='fw-bold mb-0 ms-3'>4617 BriercLiff Road, New York, NY 10011</p>
                  </div>
                </div>
              </div>

            </button>
          </h2>
          <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
            <div className="accordion-body">
              <div className="row ">
                <div className={`col-4 `}>
                  <div className='px-3'>
                    <div className={`row rounded-top ${style.backgroundColor} p-3 pb-0`}>
                      <div className="col-6">
                        <p className=''>Machine- #1-852147</p>
                      </div>
                      <div className="col-2">
                        <p className=' '>Currunt</p>
                      </div>
                      <div className="col-2">
                        <p className=''>Privious</p>
                      </div>
                      <div className="col-2">
                        <p className=''>Total</p>
                      </div>
                    </div>
                    <div className="row p-3 pb-0">
                      {machineImages.map((machineImage, index) => (
                        <div key={index} className="col-4">
                          <div className="image-container" onClick={() => openFullscreen(index)}>
                            <img src={machineImage} alt='machine'/>
                          </div>
                        </div>
                      ))}

                      <Modal show={showImageModal} onHide={() => setShowImageModal(false)} centered dialogClassName={`${style.printmodal}`}>
                        <Modal.Body >
                            <img src={machineImage1} alt='machine' className={`${style.fullscreenimage}`} />
                        </Modal.Body>
                      </Modal>
                      <div className="col-2 ps-2">
                        <p className=''>In</p>
                        <p className=''>Out</p>
                      </div>
                      <div className="col-2">
                        <p className=' '>$2500</p>
                        <p className=' '>$1200</p>
                      </div>
                      <div className="col-2">
                        <p className=''>$200</p>
                        <p className=''>$800</p>
                      </div>
                      <div className="col-2">
                        <p className=''>$500</p>
                        <p className=''>$400</p>
                      </div>
                    </div>
                    <div className={`row p-3 rounded-bottom pb-0`}>
                      <div className="col-9 text-end">
                        Profit:
                      </div>
                      <div className="col-3">
                        $100
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-4 ">
                  <div className='px-3'>
                    <div className={`row rounded-top ${style.backgroundColor} p-3 pb-0`}>
                      <div className="col-6">
                        <p className=''>Machine- #1-852147</p>
                      </div>
                      <div className="col-2">
                        <p className=' '>Currunt</p>
                      </div>
                      <div className="col-2">
                        <p className=''>Privious</p>
                      </div>
                      <div className="col-2">
                        <p className=''>Total</p>
                      </div>
                    </div>
                    <div className={`row p-3 pb-0`}>
                      {machineImages.map((machineImage, index) => (
                        <div className="col-4" key={index}>
                          <div
                            
                            onClick={() => openFullscreen(index)}
                          >
                            <img src={machineImage} alt='machine' />
                          </div>
                        </div>
                      ))}
                      <div className="col-2 ps-2">
                        <p className=''>In</p>
                        <p className=''>Out</p>
                      </div>
                      <div className="col-2">
                        <p className=' '>$2500</p>
                        <p className=' '>$1200</p>
                      </div>
                      <div className="col-2">
                        <p className=''>$200</p>
                        <p className=''>$800</p>
                      </div>
                      <div className="col-2">
                        <p className=''>$500</p>
                        <p className=''>$400</p>
                      </div>
                    </div>

                    <div className={`row p-3 rounded-bottom pb-0 `}>
                      <div className="col-9 text-end">
                        Profit:
                      </div>
                      <div className="col-3">
                        $100
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className='px-3'>
                    <div className={`row rounded-top ${style.backgroundColor} p-3 pb-0`}>
                      <div className="col-6">
                        <p className=''>Machine- #1-852147</p>
                      </div>
                      <div className="col-2">
                        <p className=' '>Currunt</p>
                      </div>
                      <div className="col-2">
                        <p className=''>Privious</p>
                      </div>
                      <div className="col-2">
                        <p className=''>Total</p>
                      </div>
                    </div>
                    <div className={`row p-3 pb-0 `}>
                      {machineImages.map((machineImage, index) => (
                        <div className="col-4" key={index}>
                          <div
                            
                            onClick={() => openFullscreen(index)}
                          >
                            <img src={machineImage} alt='machine' />
                          </div>
                        </div>
                      ))}
                      <div className="col-2 ps-2">
                        <p className=''>In</p>
                        <p className=''>Out</p>
                      </div>
                      <div className="col-2">
                        <p className=' '>$2500</p>
                        <p className=' '>$1200</p>
                      </div>
                      <div className="col-2">
                        <p className=''>$200</p>
                        <p className=''>$800</p>
                      </div>
                      <div className="col-2">
                        <p className=''>$500</p>
                        <p className=''>$400</p>
                      </div>
                    </div>

                    <div className={`row p-3 rounded-bottom pb-0`}>
                      <div className="col-9 text-end">
                        Profit:
                      </div>
                      <div className="col-3">
                        $100
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className={`col-4 `}>
                  <div className='px-3'>
                    <div className={`row rounded-top  ${style.backgroundColor} p-3 pb-0`}>
                      <div className="col-5">
                        <p className=''>Machine- #1-852147</p>
                      </div>
                      <div className="col-2">
                        <p className=' '>Currunt</p>
                      </div>
                      <div className="col-2">
                        <p className=''>Privious</p>
                      </div>
                      <div className="col-3">
                        <p className=''>Total</p>
                      </div>
                    </div>
                    <div className={`row p-3 pb-0 `}>
                      {machineImages.map((machineImage, index) => (
                        <div className="col-4" key={index}>
                          <div
                            
                            onClick={() => openFullscreen(index)}
                          >
                            <img src={machineImage} alt='machine' />
                          </div>
                        </div>
                      ))}
                      <div className="col-2 ps-2">
                        <p className=''>In</p>
                        <p className=''>Out</p>
                      </div>
                      <div className="col-2">
                        <p className=' '>$2500</p>
                        <p className=' '>$1200</p>
                      </div>
                      <div className="col-2">
                        <p className=''>$200</p>
                        <p className=''>$800</p>
                      </div>
                      <div className="col-2">
                        <p className=''>$500</p>
                        <p className=''>$400</p>
                      </div>
                    </div>
                    <div className={`row p-3 rounded-bottom pb-0`}>
                      <div className="col-9 text-end">
                        Profit:
                      </div>
                      <div className="col-3">
                        $100
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-4 ">
                  <div className='px-3'>
                    <div className={`row rounded-top ${style.backgroundColor} p-3 pb-0`}>
                      <div className="col-5">
                        <p className=''>Machine- #1-852147</p>
                      </div>
                      <div className="col-2">
                        <p className=' '>Current</p>
                      </div>
                      <div className="col-2">
                        <p className=''>Privious</p>
                      </div>
                      <div className="col-3">
                        <p className=''>Total</p>
                      </div>
                    </div>
                    <div className={`row p-3 pb-0`}>
                      {machineImages.map((machineImage, index) => (
                        <div className="col-4" key={index}>
                          <div
                            
                            onClick={() => openFullscreen(index)}
                          >
                            <img src={machineImage} alt='machine' />
                          </div>
                        </div>
                      ))}
                      <div className="col-2 ps-2">
                        <p className=''>In</p>
                        <p className=''>Out</p>
                      </div>
                      <div className="col-2">
                        <p className=' '>$2500</p>
                        <p className=' '>$1200</p>
                      </div>
                      <div className="col-2">
                        <p className=''>$200</p>
                        <p className=''>$800</p>
                      </div>
                      <div className="col-2">
                        <p className=''>$500</p>
                        <p className=''>$400</p>
                      </div>
                    </div>
                    <div className={`row p-3 rounded-bottom pb-0 `}>
                      <div className="col-9 text-end">
                        Profit:
                      </div>
                      <div className="col-3">
                        $100
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className='px-3'>
                    <div className={`row rounded-top ${style.backgroundColor} p-3 pb-0`}>
                      <div className="col-5">
                        <p className=''>Machine- #1-852147</p>
                      </div>
                      <div className="col-2">
                        <p className=' '>Currunt</p>
                      </div>
                      <div className="col-2">
                        <p className=''>Privious</p>
                      </div>
                      <div className="col-3">
                        <p className=''>Total</p>
                      </div>
                    </div>
                    <div className={`row p-3 pb-0  `}>
                      {machineImages.map((machineImage, index) => (
                        <div className="col-4" key={index}>
                          <div
                            
                            onClick={() => openFullscreen(index)}
                          >
                            <img src={machineImage} alt='machine' />
                          </div>
                        </div>
                      ))}
                      <div className="col-2 ps-2">
                        <p className=''>In</p>
                        <p className=''>Out</p>
                      </div>
                      <div className="col-2">
                        <p className=' '>$2500</p>
                        <p className=' '>$1200</p>
                      </div>
                      <div className="col-2">
                        <p className=''>$200</p>
                        <p className=''>$800</p>
                      </div>
                      <div className="col-2">
                        <p className=''>$500</p>
                        <p className=''>$400</p>
                      </div>
                    </div>
                    <div className={`row p-3 rounded-bottom pb-0 `}>
                      <div className="col-9 text-end">
                        Profit:
                      </div>
                      <div className="col-3">
                        $100
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-4 text-end">
                  Total Profit- $1000
                </div>
                <div className="col-2 text-center">
                  50%- $500
                </div>
                <div className="col-2 text-center">
                  Paid- $500
                </div>
                <div className="col-4">
                  Due- 0
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end mt-3">
            <button className={`${style.createbtn}`} onClick={handleAccordionItemClick}>
              Print Report
            </button>
          </div>
        </div>
      </div>

      <Modal
        show={showModal}
        onHide={handleModalClose}
        centered
        dialogClassName={`${style.printmodal}`}
      >
        <Modal.Header closeButton>
          <Modal.Title>Accordion Data</Modal.Title>
        </Modal.Header>
        <Modal.Body
          id="modalContent"
        >
          <div className="row">
            <style>
              {`
          @media print {
            .machinetable {
              
            }
            .maintablewrap {
              margin-top: 50px;
              display: flex;
              flex-wrap: wrap;
              gap: 20px;
              align-items: center;
              justify-content: center;
              width: 100%;
            }
            .totalprofit{
              display: flex;
              align-items: center;
              justify-content: start;
              gap: 10px;
            }
            .total{
              margin: 0px;
            }
            /* Add any other print-specific styles as needed */
          }
        `}
            </style>
            <div className={`col-12`}>
              <table className="table">
                <thead>
                  <tr>
                    <th style={{ padding: '10px' }}>Date</th>
                    <th style={{ padding: '10px' }}>Employee Name</th>
                    <th style={{ padding: '10px' }}>Location</th>
                    <th style={{ padding: '10px' }}>Machine</th>
                    <th style={{ padding: '10px' }}>Beginning</th>
                    <th style={{ padding: '10px' }}>Closed</th>
                    <th style={{ padding: '10px' }}>Location Address</th>
                  </tr>
                  <tr>
                    <th style={{ padding: '10px' }}>12/09/2023</th>
                    <th style={{ padding: '10px' }}>emp23</th>
                    <th style={{ padding: '10px' }}>locationname</th>
                    <th style={{ padding: '10px' }}>6</th>
                    <th style={{ padding: '10px' }}>4</th>
                    <th style={{ padding: '10px' }}>2</th>
                    <th style={{ padding: '10px' }}>Location Address</th>
                  </tr>
                </thead>
                <tbody>
                  {/* Map through your data and render each row */}
                  {/* {accordionData.map((item, index) => (
        // Render your data rows here
      ))} */}
                </tbody>
              </table>
            </div>
            <div className={`col-12 gap-5 d-flex flex-wrap maintablewrap`}>
              <div className=''>
                <Table striped >
                  <thead>
                    <tr>
                      <th>machine number</th>
                      <th>Current</th>
                      <th>Previous</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>In</td>
                      <td>$2500</td>
                      <td>$2000</td>
                      <td>$500</td>
                    </tr>
                    <tr>
                      <td>Out</td>
                      <td>$1200</td>
                      <td>$800</td>
                      <td>$400</td>
                    </tr>
                    <tr>
                      <td>Profit</td>
                      <td></td>
                      <td></td>
                      <td>$100</td>
                    </tr>
                  </tbody>
                </Table>
                <img src={machineImage1} alt='machin' />
              </div>
              <div>
                <Table striped >
                  <thead>
                    <tr>
                      <th>machine number</th>
                      <th>Current</th>
                      <th>Previous</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>In</td>
                      <td>$2500</td>
                      <td>$2000</td>
                      <td>$500</td>
                    </tr>
                    <tr>
                      <td>Out</td>
                      <td>$1200</td>
                      <td>$800</td>
                      <td>$400</td>
                    </tr>
                    <tr>
                      <td>Profit</td>
                      <td></td>
                      <td></td>
                      <td>$100</td>
                    </tr>
                  </tbody>
                </Table>
                <img src={machineImage1} alt='machin ' />
              </div>
              <div>
                <Table striped >
                  <thead>
                    <tr>
                      <th>machine number</th>
                      <th>Current</th>
                      <th>Previous</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>In</td>
                      <td>$2500</td>
                      <td>$2000</td>
                      <td>$500</td>
                    </tr>
                    <tr>
                      <td>Out</td>
                      <td>$1200</td>
                      <td>$800</td>
                      <td>$400</td>
                    </tr>
                    <tr>
                      <td>Profit</td>
                      <td></td>
                      <td></td>
                      <td>$100</td>
                    </tr>
                  </tbody>
                </Table>
                <img src={machineImage1} alt='machin ' />
              </div>
              <div>
                <Table striped >
                  <thead>
                    <tr>
                      <th>machine number</th>
                      <th>Current</th>
                      <th>Previous</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>In</td>
                      <td>$2500</td>
                      <td>$2000</td>
                      <td>$500</td>
                    </tr>
                    <tr>
                      <td>Out</td>
                      <td>$1200</td>
                      <td>$800</td>
                      <td>$400</td>
                    </tr>
                    <tr>
                      <td>Profit</td>
                      <td></td>
                      <td></td>
                      <td>$100</td>
                    </tr>
                  </tbody>
                </Table>
                <img src={machineImage1} alt='machin ' />
              </div>
            </div>
            <div className='total'>
              <div className='d-flex totalprofit'>
                <h6>Total Profit:</h6>
                <h6>$1000</h6>
              </div>
              <div className='d-flex totalprofit'>
                <h6>50%: </h6>
                <h6>$500</h6>
              </div>
              <div className='d-flex totalprofit'>
                <h6>Due: </h6>
                <h6>0</h6>
              </div>
            </div>
          </div>

        </Modal.Body>
        <Modal.Footer>
          <button className={`${style.closebtn}`} onClick={handleModalClose}>
            Close
          </button>
          <button className={`${style.createbtn}`} onClick={() => { handlePrint(); handleModalClose(); }}>
            Print Report
          </button>
        </Modal.Footer>
      </Modal>

      <div className="d-flex justify-content-end">
        <div className='d-flex align-items-center me-3'>
          <div className='me-1'>
            Rows Per Page:
          </div>
          <div>
            <Form.Select size="sm" value={n} onChange={handlePerPageChange}>
              {perPageOptions.map((option, idx) => (
                <option key={idx} value={option.value}>{option.label}</option>
              ))}
            </Form.Select>
          </div>
        </div>
        <ReactPaginate
          containerClassName={"pagination align-items-center mb-0"}
          activeClassName={`${style.pagination}`}
          pageClassName={"page-item p-2 px-3"}
          onPageChange={handlePageChange}
          breakLabel={<span className="break-label">...</span>}
          // pageCount={Math.ceil(locations?.length / n)}
          previousLabel={
            <IconContext.Provider value={{ color: "#B8C1CC", size: "36px" }}>
              <AiFillLeftCircle />
            </IconContext.Provider>
          }
          nextLabel={
            <IconContext.Provider value={{ color: "#B8C1CC", size: "36px" }}>
              <AiFillRightCircle />
            </IconContext.Provider>
          }
          pageRangeDisplayed={2}
          marginPagesDisplayed={2}
        />
      </div>
    </>
  )
}

export default CollectionReportList